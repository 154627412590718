import AirplanemodeActiveIcon from '@mui/icons-material/AirplanemodeActive';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { Accordion, AccordionDetails, AccordionSummaryProps, Avatar, Box, Card, CardHeader, List, ListItem, ListItemAvatar, ListItemText, Paper, SvgIcon, Typography, styled } from "@mui/material";
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import { green, orange } from '@mui/material/colors';
import blueGrey from '@mui/material/colors/blueGrey';
import { StompSubscription } from '@stomp/stompjs';
import React, { useCallback, useEffect, useState } from 'react';
import { useStompClient } from 'react-stomp-hooks';
import { SessionWS, StateInterface, Type, User, UserWS, config } from "../App";



let sessionSubscrition: StompSubscription | undefined;
let playerSubscrition: StompSubscription | undefined;

const emptyStateArray : StateInterface[] = [];

const Server = () => {
    //const { state } = props;
    const [ state, setState ] = useState<StateInterface[]>(emptyStateArray);
    const [ reload, setReload ] = useState(false);

    const stompClient = useStompClient();

    function getModel(model: String) {
        if (model !== null) {
            let returnValue = model;
            if (model.indexOf('&') !== -1) {
                returnValue = model.substring(0, model.indexOf('&'));
                let splitedModel = returnValue.split('/');
                return splitedModel[1];
            } else {
                let splitedModel = model.split('/');
                return splitedModel[1];
            }
        }
        return "";
    }
    
    function getType(model: String) {
        if (model !== null) {
            let splitedModel = model.split('/');
            if (splitedModel[0].includes("helicopter")) {
                return <SvgIcon>
                {/* credit: plus icon from https://heroicons.com/ */}
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 26 26"><g transform="translate(26 0) scale(-1 1)"><path fill="currentColor" d="M14.813 7.531a.95.95 0 0 0-.75.969v.063L5 8.063A.93.93 0 0 0 4.062 9A.93.93 0 0 0 5 9.938l9.063-.5v2.093A4.41 4.41 0 0 0 12.28 13H2.5c-.5 0-.619.844-.063.938c.153.025 5.561 1.345 8.75 2.124c-.057.447-.093.9-.093 1.375c0 3.525 3.079 3.532 6.875 3.532s6.843-.006 6.843-3.532c0-3.522-4.609-6.375-8.406-6.375c-.162 0-.315.022-.468.032V9.438l9.062.5A.93.93 0 0 0 25.938 9A.93.93 0 0 0 25 8.062l-9.063.5V8.5a.95.95 0 0 0-1.03-.969a.95.95 0 0 0-.095 0zM2.5 11.188a2.312 2.312 0 1 0 0 4.624a2.28 2.28 0 0 0 1.813-.906l-1.22-.344c-.177.1-.376.188-.593.188c-.686 0-1.25-.565-1.25-1.25s.565-1.25 1.25-1.25c.332 0 .622.13.844.344h1.281A2.293 2.293 0 0 0 2.5 11.188m15.625 1.874c1.78 0 4.844 1.663 4.844 3.75c0 .782-.81 1.157-3.563 1.157c-1.806 0-2.312-.84-2.312-1.907c0-1.613.198-3 1.031-3m6.688 7.97a.95.95 0 0 0-.688.593c.01-.01.035-.017-.063.031c-.277.139-1.15.407-3.062.407H10a.95.95 0 1 0 0 1.875h11c2.071 0 3.18-.233 3.906-.594c.363-.181.641-.404.813-.657c.086-.126.152-.257.187-.375c.036-.117.032-.28.032-.28a.95.95 0 0 0-1.032-1a.95.95 0 0 0-.093 0M902 1469v2h26v-2zm4 5v2h18v-2zm-4 5v2h26v-2zm4 5v2h18v-2zm-4 5v2h26v-2z"/></g></svg>
                </SvgIcon>;
            } else {
                return <AirplanemodeActiveIcon/>;
            }
        }
        return "";
    }

    useEffect(() => {
        fetch(config.baseUrl + "/state")
            .then((res) => {
            if (!res.ok) {
                throw new Error("Anfrage fehlgeschlagen");
            }
            return res.json();
            })
            .then((data) => {
            setState(data);
            })
            .catch((error) => console.log(error));
    }, [reload]);

    // Reload every 60s
    useEffect(() => {
        const interval = setInterval(() => {
            setReload((reload) => !reload);
        }, 60000);
    
        return () => clearInterval(interval);
    }, []);
    
        
    const handleSessionEvent = useCallback((session: SessionWS) => {
        console.log("insertOrUpdateSession");
        const newState = [...state];
        switch (session.type) {
            case Type.NEW:
            case Type.UPDATE:
                console.log("insert or update: " + JSON.stringify(session.session));
                const objIndex = newState.findIndex((obj => obj.name === session.session.name));
                if (objIndex === -1) {
                    // not found
                    newState.push(session.session);
                } else {
                    // existiert bereits -> update
                    newState[objIndex] = session.session;
                }
                break;
            case Type.DELETE:
                const index = newState.findIndex((obj => obj.name === session.session.name));
                console.log("Remove session " + session.session.name + " with index " + index + " from array");
                if (index !== -1) {
                    newState.splice(index, 1);
                    setState(newState);
                }
                break;
        }
        setState(newState);
    }, [state]);

    const handleUserEvent = useCallback((player: UserWS) => {
        const newState = [...state];
        const sessionIndex = state?.findIndex((obj => obj.name === player.session));
        if (sessionIndex === -1) {
            // Session not found, so reaload complete list
            setReload((reload) => !reload);
        } else {
            switch (player.type) {
                case Type.NEW:
                case Type.UPDATE:
                  //  console.log("insert or update: " + JSON.stringify(player.user));
                    const userIndex = newState[sessionIndex].users.findIndex((obj => obj.playerId === player.user.playerId));
                    if (userIndex === -1) {
                        // not found
                        newState[sessionIndex].users.push(player.user);
                    } else {
                        // existiert bereits -> update
                        newState[sessionIndex].users[userIndex] = player.user;
                    }
                    break;
                case Type.DELETE:
                    const userDelIndex = newState[sessionIndex].users.findIndex((obj => obj.playerId === player.user.playerId));
                  //  console.log("Remove user " + player.user.playerId + " with index " + userDelIndex + " from array in session " + player.session);
                    if (userDelIndex !== -1) {
                        newState[sessionIndex].users.splice(userDelIndex, 1);
                        setState(newState);
                    }
                    break;
            }
            setState(newState);
        }
    }, [state]);

    useEffect(() => {
        if (stompClient !== undefined && stompClient.connected) {
            
            if (sessionSubscrition !== undefined) {
                sessionSubscrition.unsubscribe();
                sessionSubscrition = undefined;
            }
            if (sessionSubscrition === undefined && state !== undefined) {
                
                sessionSubscrition = stompClient.subscribe("/session", (message: { body: string; }) => {
                    const session: SessionWS = JSON.parse(message.body);
                    handleSessionEvent(session);
                });
            }
            if (playerSubscrition !== undefined) {
                playerSubscrition.unsubscribe();
                playerSubscrition = undefined;
            }
            if (playerSubscrition === undefined) {
                playerSubscrition = stompClient.subscribe("/player", (message: { body: string; }) => {
                    const player: UserWS = JSON.parse(message.body);
                    handleUserEvent(player);
                });
            }
            
        }
      }, [stompClient, state, handleUserEvent, handleSessionEvent]);

    const AccordionSummary = styled((props: AccordionSummaryProps) => (
        <MuiAccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ fontSize: '0.9rem' }} />}
          {...props}
        />
      ))(({ theme }) => ({
        backgroundColor: blueGrey[200],
        flexDirection: 'row-reverse',
        '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
          transform: 'rotate(90deg)',
        },
        '& .MuiAccordionSummary-content': {
          marginLeft: theme.spacing(1),
        },
      }));

    function renderPlayer(players: User[], session: string) {
        const sortedUsers = Array.from(players).sort((a, b) => a.name.localeCompare(b.name));
        return (
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                    >
                    Show Player
                </AccordionSummary> 
                <AccordionDetails key={session}>
                    <List sx={{ width: '100%' }}>
                        {sortedUsers.map((row) => (
                            
                            <Paper elevation={1} sx={{mb:1, minHeight:72
                            }} key={session + row.playerId + row.name}>
                                <ListItem alignItems="flex-start" >
                                    <ListItemAvatar>
                                    <Avatar  sx={{ bgcolor: blueGrey[500] }}>
                                        {getType(row.helicopter)}
                                    </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary={row.name} secondary={
                                        <React.Fragment>
                                            <Typography
                                            sx={{ display: 'inline' }}
                                            component="span"
                                            variant="body2"
                                            color="text.primary"
                                            >
                                            {getModel(row.helicopter)}
                                            </Typography>
                                        </React.Fragment>
                                    }
                                    />
                                </ListItem>
                            </Paper>
                            
                        ))}
                    </List>
                </AccordionDetails>
            </Accordion>
        );
    }


    function stringAvatar(privChannel: boolean) {
        if (privChannel) {
            return <Avatar sx={{ bgcolor: orange[500] }}><LockIcon/></Avatar>;
        } else {
            return <Avatar sx={{ bgcolor: green[500] }}><LockOpenIcon/></Avatar>;
        }
    }

    function createSubHeader(place: string, players: number) {
        return (
            <>
            Place: {place}<br></br>
            Player: {players}
            </>
            );
    }

    function renderData(states: StateInterface[]) {
        const sortedStates = Array.from(states).sort((a, b) => a.name.localeCompare(b.name));
        return Object.values(sortedStates).map((statevalue) => (
            
            <Card key={statevalue.name} elevation={3} sx={{mb: 1, mt: 1}}>
                <CardHeader title={statevalue.name} subheader={createSubHeader(statevalue.place, statevalue.users.length)}
                avatar={stringAvatar(statevalue.private)}
                />
                {statevalue.users.length > 0 && renderPlayer(statevalue.users, statevalue.name)}
            </Card>
        ));
    }

    return (
        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': { mt: 1},
            }}
            noValidate
            autoComplete="off"
           
        >
            {renderData(state)}
        </Box>
    );
}

export default Server;